
    import { Component, Vue } from 'vue-property-decorator';
    import { ErpCar } from '@/interfaces/cars/ErpCar';
    import auth from '@/store/auth/auth';
    import { fetchAvailableErpCars } from '@/api/ErpCars';
    import { getFixtureLevelName } from '@/utils/misc';

    @Component
    export default class SearchCar extends Vue {
        existingCarSearchValue = '';
        existingCars: ErpCar[] = [];

        buildDisplayName(carData: ErpCar) {
            let fl = '-';

            if (carData.fixture_level) {
                fl = getFixtureLevelName(carData.fixture_level);
            }

            return `${carData.id} / ${carData.country_of_use.toUpperCase()} / ${
                carData.license_plate
            } / ${carData.vin_sn || '-'} / ${carData.purchase_contract_number || '-'} / ${
                carData.model || '-'
            } / ${carData.model_year} /
                ${fl} / ${carData.color || '-'}`;
        }

        async fetchErpCars() {
            let response = await fetchAvailableErpCars();

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await fetchAvailableErpCars();
            }

            const cars = await response.parsedBody;

            if (cars) {
                return cars;
            } else {
                throw new Error('Error while getting cars.');
            }
        }

        get filteredExistingCars() {
            return this.existingCars.filter((option) => {
                const searchModel =
                    option.model &&
                    option.model
                        .toString()
                        .toLowerCase()
                        .indexOf(this.existingCarSearchValue.toLowerCase()) >= 0;

                const searchLicensePlate =
                    option.license_plate &&
                    option.license_plate
                        .toString()
                        .toLowerCase()
                        .indexOf(this.existingCarSearchValue.toLowerCase()) >= 0;

                const searchVin =
                    option.vin_sn &&
                    option.vin_sn
                        .toString()
                        .toLowerCase()
                        .indexOf(this.existingCarSearchValue.toLowerCase()) >= 0;

                const searchID =
                    option.id &&
                    option.id
                        .toString()
                        .toLowerCase()
                        .indexOf(this.existingCarSearchValue.toLowerCase()) >= 0;

                const searchPurchaseContractNumber =
                    option.purchase_contract_number &&
                    option.purchase_contract_number
                        .toString()
                        .toLowerCase()
                        .indexOf(this.existingCarSearchValue.toLowerCase()) >= 0;

                return (
                    searchModel ||
                    searchLicensePlate ||
                    searchVin ||
                    searchID ||
                    searchPurchaseContractNumber
                );
            });
        }

        formatDisplayCar(option: ErpCar) {
            return `${option.model} / ${option.license_plate}`;
        }

        async mounted() {
            this.existingCars = await this.fetchErpCars();
        }
    }
